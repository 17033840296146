@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@layer base {
    html {
        overflow-x: hidden;
    }
}

@layer components {
    .container {
        /* @apply mx-auto w-full px-4 lg:px-4 lg:w-[90%] max-w-[1200px]; */
    }

    .svg_icon {
        @apply transition-all;
    }
}

svg > g {
    transform: translate(20px, 23px);
}

/* paths generated dynamically in icons.js need to be centred */

.graded {
    /* background: linear-gradient(179.94deg, rgba(30, 180, 230, 0.61), #F6FDFF); */
    /* background: linear-gradient(180deg, rgba(214,241,250), #FFF) */
    background: linear-gradient(180deg, #d6f0fa, #fff);
}

/* TODO: Is there a way to put this into tailwind utils, rather than do here. */
#__next {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.formGradientBlueBackground {
    background: linear-gradient(180deg, rgba(30, 180, 230, 0.05) 20.06%, rgba(246, 253, 255, 0.3) 94.36%);
}

.formGradientBlueBackground2 {
    background: linear-gradient(180deg, rgba(30, 180, 230, 0.2) 20.06%, rgba(246, 253, 255, 0.3) 94.36%);
}

@layer base {
    html {
        font-family: "akzidenz-grotesk-pro-thin", Helvetica, Arial, sans-serif;
    }

    .font-bold {
        font-family: "akzidenz-grotesk-pro-bold", Helvetica, Arial, sans-serif;
    }

    .font-grotesk {
        font-family: "akzidenz-grotesk-pro-extrabold", Helvetica, Arial, sans-serif;
    }

    table .arrows {
        display: none;
    }

    table.survey-listing-table .arrows {
        display: block;
    }

    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        --tw-ring-color: #1EB4E6;
        border-color: var(--tw-ring-color);
    }

    [type="radio"]:focus {
        --tw-ring-color: #1EB4E6;
        background-color: #1EB4E6 !important;
    }

    [type='search']:focus {
        box-shadow: none;
    }
}

.tooltipClass {
    font-size: 14px !important;
    pointer-events: auto !important;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
    text-align: left !important;
}

.tooltipClass .multi-line {
    text-align: left !important;
}

.tooltipClass:hover {
    visibility: visible !important;
    opacity: 1 !important;

}

.tooltipProgressBar {
    font-size: 14px;
    font-family: "akzidenz-grotesk-pro-bold";
    color: #004664;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
}

[type='checkbox']:focus, [type='radio']:focus, [type='button']:focus {
    outline: 0px;
    --tw-ring-offset-width: 0px;
    --tw-ring-shadow: 0px
}


input[type="date"] {
    position: relative;
}

/* Remove icon after datepicker */
input[type="date"]:after {
    content: "";
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

 /* adjust clear button */
 input[type="date"]::-webkit-clear-button {
     z-index: 1;
 }

.__react_component_tooltip.show {
    opacity: 1 !important;
}

.react-colorful-small .react-colorful {
    width: 120px;
    height: 120px;
}

.react-colorful-small .react-colorful__hue {
    height: 20px;
}


.rdw-editor-toolbar {
    background-color: #f0f0f1;
}

.rdw-editor-wrapper .rdw-editor-toolbar{
    display: inline-flex;
    background-color: #f0f0f1;
    font-weight: lighter;
    margin-bottom: -1.8px;
    width: 50%;
}
.rdw-inline-wrapper .rdw-option-wrapper{
    background-color: #f0f0f1;
    font-weight: none;
    border: 0;

}
.rdw-list-wrapper .rdw-option-wrapper{
    font-weight: none;
    background-color: #f0f0f1;
    border: 0;
    opacity: 1;
    color: black;
}


.__react_component_tooltip.show {
    opacity: 1 !important;
  }

@media only screen and (max-width: 767px) {
    .splashScreenGradient {
            background: linear-gradient(-40deg, #C4E9FE 9.85%, rgba(0, 158, 226, 0.321678) 78.75%, rgba(0, 132, 189, 0.0001) 103.46%), url('/imgs/survey-splashscreen-mobile.png');
            background-repeat: no-repeat;
            background-size: cover;
    }
}

@media only screen and (min-width: 768px) {
    .splashScreenGradient {
        background: linear-gradient(-40deg, #C4E9FE 9.85%, rgba(0, 158, 226, 0.321678) 78.75%, rgba(0, 132, 189, 0.0001) 103.46%), url('/imgs/survey-splashscreen-wide.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

